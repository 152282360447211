import { useApi } from '@tenant/composables'

export type Salutation = {
    id: string
    value: string
    label: string
}

export type SalutationState = {
    salutations: Salutation[]
}

export default {
    state: (): SalutationState => ({
        salutations: [],
    }),

    mutations: {
        setSalutations(state: SalutationState, salutations: Salutation[]) {
            state.salutations = salutations
        },
    },

    getters: {
        salutations(state: SalutationState) {
            return state.salutations
        },
    },

    actions: {
        fetchSalutations({ commit }: { commit: (...args: any[]) => void }) {
            const { execute } = useApi('/api/salutations', 'GET')

            execute().then((response) => {
                const salutations = response.map(
                    (salutation: { id: string; name: string }) => ({
                        value: salutation.id,
                        label: salutation.name,
                    })
                )

                commit('setSalutations', salutations)
            })
        },
    },
}
